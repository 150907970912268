import axios from 'axios'

export default class CountryService {

    getCountries() {
        return axios.get(`${process.env.VUE_APP_OPEN_URL}v1/countries`).then(res => {
            return res.data
        });
    }
}
